import { Link } from '@reach/router'
import { PageRendererProps } from 'gatsby'
import { useIntl } from 'gatsby-plugin-intl'
import React from 'react'
import styled from 'styled-components'

import consultImage from '../../assets/static/consult/consult5.jpg'
import { GridLayout } from '../../components/Grid'
import ServiceLayout from '../../components/Layout/ServiceLayout'
import Seo from '../../components/Seo'
import { ContentTitle } from '../../components/Titles/ContentTitle'
import { space } from '../../constants/length'
import { LightGrey, Primary } from '../../constants/newColor'
import { textSize } from '../../constants/text'
import { viewport } from '../../constants/viewport'
import Collapse from './components/Collapse'
import ContentSection from './components/ContentSection'

const StyledContentTitle = styled(ContentTitle)`
  margin-bottom: ${space.l}px;
`
const QuestionSection = styled.div`
  margin-top: ${space.l * 2}px;
`
const TreatmentSection = styled.div`
  margin-bottom: ${space.xxl * 2}px;
`

const TreatmentHead = styled.div`
  color: ${Primary};
  text-align: center;
  padding-bottom: ${space.s}px;
  font-size: ${textSize.h3Web}px;
  line-height: 30px;
`
const TreatmentContent = styled.div`
  padding-bottom: ${space.m}px;
`
const TreatmentTitle = styled.div`
  color: ${Primary};
  font-size: ${textSize.h3Mobile}px;
  line-height: 34px;
`

const TreatmentBlock = styled.div`
  padding: ${space.m}px ${space.m}px ${space.xl}px ${space.m}px;
  background-color: ${LightGrey};
  border-color: ${Primary};
  border-radius: 10px;
  border-width: 4px 0;

  ${TreatmentContent} + ${TreatmentTitle} {
    margin-top: ${space.s}px;
  }

  @media (max-width: ${viewport.tablet - 1}px) {
    margin-bottom: ${space.m}px;
  }
`

/** 牙齒美白 */
const TeethWhitening = (props: PageRendererProps) => {
  const { formatMessage } = useIntl()

  const routes = [
    {
      label: formatMessage({ id: 'breadcrumb.index' }),
      path: '/'
    },
    {
      label: formatMessage({ id: 'service.index' }),
      path: '/service/digital'
    },
    {
      label: formatMessage({ id: 'service.teethWhitening' }),
      path: '/service/teethWhitening'
    }
  ]

  return (
    <ServiceLayout routes={routes} consultImage={consultImage} {...props} pageBannerInfo={formatMessage({ id: 'banner.teethWhitening' })}>
      <Seo
        title='冷光美白推薦：不酸、不敏感的牙齒美白療程就在日不落牙醫集團'
        metaDescription='美白牙齒別亂做，交給遍布台北、新竹、台中、台南的日不落牙醫集團準沒錯！主打『不酸、不敏感』的美白療程，提供冷光美白、居家美白、噴砂美白等服務，讓你在安全的診療過程下重獲亮白自信。'
      />
      <StyledContentTitle>{formatMessage({ id: 'service.teethWhitening' })}</StyledContentTitle>
      <ContentSection title='美白牙齒怎麼做？'>
        <p>
          美妝大師曾說過：<strong>當牙齒白了，唇色就顯得紅潤、氣色自然就好</strong>，想要有完美裸妝，第一步就是先把齒色變白。
        </p>
        <p>
          目前日不落牙醫集團提供的牙齒美白分成：冷光美白、居家美白、噴砂美白。前兩種主要是靠美白藥劑的氧化還原作用，將牙齒表面的頑垢髒污帶走，兩者差異在於"冷光"的加速催化作用，以及使用藥劑濃度的不同。
        </p>
        <p>
          我們主打美白療程特色：<strong>『不酸、不敏感』</strong>，每次冷光療程前，皆經由醫師評估牙齒強健程度再進行美白療程，才能將美白療程術後不適感降到最低。
        </p>
      </ContentSection>
      <TreatmentSection>
        <GridLayout templateColumns={['100%', '5fr 4fr']}>
          <ContentSection id='cold-light' title='冷光美白'>
            <p>
              <strong>想要快速美白牙齒的人，可以立即見效</strong>
            </p>
            <p>冷光美白是利用藍光（其溫度改變不明顯，稱為冷光），催化牙齒上的藥劑，經由過氧化氫的氧化還原作用產生游離基，改變色素的碳環結構，使牙齒顏色變亮白。</p>
          </ContentSection>
          <TreatmentBlock>
            <TreatmentHead>療程選擇</TreatmentHead>
            <TreatmentTitle>頂級亮白療程($22500)</TreatmentTitle>
            <TreatmentContent>兩次照光療程 + 四管術後保養居家美白藥劑</TreatmentContent>
            <TreatmentTitle>極致亮白療程($16000)</TreatmentTitle>
            <TreatmentContent>一次照光療程 + 兩管術後保養居家美白藥劑</TreatmentContent>
            <TreatmentTitle>快速美白療程($12000)</TreatmentTitle>
            <TreatmentContent>一次照光療程</TreatmentContent>
          </TreatmentBlock>
        </GridLayout>
      </TreatmentSection>
      <TreatmentSection>
        <GridLayout templateColumns={['100%', '5fr 4fr']}>
          <ContentSection id='domestic' title='居家美白'>
            <p>
              <strong>想要舒服進行療程的人，溫合不刺激</strong>
            </p>
            <p>居家美白是利用個人居家美白牙托當載體，把藥劑擠入牙托內再配戴，使藥劑在牙齒表面均勻接觸作用，產生美白效果。</p>
          </ContentSection>
          <TreatmentBlock>
            <TreatmentHead>療程選擇</TreatmentHead>
            <TreatmentTitle>居家美白套組($8000)</TreatmentTitle>
            <TreatmentContent>2管居家美白藥劑 + 客製化美白牙托</TreatmentContent>
          </TreatmentBlock>
        </GridLayout>
      </TreatmentSection>
      <TreatmentSection>
        <GridLayout templateColumns={['100%', '5fr 4fr']}>
          <ContentSection id='sandblast' title='噴砂美白'>
            <p>
              <strong>因髒污堆積而造成齒色變黃的人</strong>
            </p>
            <p>利用高壓的噴砂機，將小蘇打粉配合強力的水柱，將牙菌斑、茶漬、咖啡垢及煙垢去除。主要適用於牙齒堆積大量黃或黑色垢者</p>
          </ContentSection>
          <TreatmentBlock>
            <TreatmentHead>療程選擇</TreatmentHead>
            <TreatmentTitle>重度菸垢($5000)</TreatmentTitle>
            <TreatmentTitle>中度咖啡垢($3000~4000)</TreatmentTitle>
            <TreatmentTitle>輕度齒垢($2000)</TreatmentTitle>
          </TreatmentBlock>
        </GridLayout>
      </TreatmentSection>
      <QuestionSection>
        <ContentSection title='牙齒美白 常見問題'>
          <Collapse title='什麼情況不適合作美白?'>
            <p>孕婦</p>
            <p>帶著假牙的牙齒：因為牙冠是不會變色的，若使用美白恐造成牙齒色差。</p>
            <p>對過氧化物 (例如：雙氧水) 過敏者</p>
          </Collapse>
          <Collapse title='牙齒美白會傷害牙齒嗎?'>
            <p>本院選用牙齒美白藥劑皆屬低敏配方，對牙齒傷害程度極低。且每次美白療程前，皆會經過醫師檢查牙齒狀況再進行，降低任何傷害牙齒的風險。</p>
          </Collapse>
          <Collapse title='美白效果維持多久?'>
            <p>
              本院提供之冷光美白一次可白2~5個色階 (數據皆由 3M Vita 比色儀測得)，通常療程約可維持 3~4 個月，建議選用極致亮白療程
              ($16000)，同時搭配居家美白藥劑自行在家使用保養齒色，可延長美白效果。
            </p>
            <p>更建議：少喝茶、少喝咖啡、少吃色素較重的食物，改掉平時不好的飲食習慣，並且戒菸、戒檳榔，除了能讓牙齒能維持亮白，也可以促進身體健康。</p>
          </Collapse>
          <Collapse title='有無一勞永逸的美白方法?'>
            <p>
              請參考{' '}
              <Link to='/service/veneers'>
                <strong>全瓷美學{'>'}陶瓷貼片</strong>
              </Link>
              ，任意齒色皆可指定，且永久改變齒白。
            </p>
          </Collapse>
        </ContentSection>
      </QuestionSection>
    </ServiceLayout>
  )
}

export default TeethWhitening
